/* eslint-disable @typescript-eslint/ban-types */
import {
  addItemOnCart,
  buildItem,
  ICartItemDTO,
  ICartDTO,
} from 'components/Cart';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FaCartPlus, FaCartArrowDown } from 'react-icons/fa';
import { ILivroDTO, ISerieDTO } from 'utils/dto';

import SemCapa from 'assets/livro-indefinido.png';
import { DisciplinaContainer, LivroContainer, Marcador } from './styles';

interface IProps {
  livro: ILivroDTO;
  serie: ISerieDTO;
}

const Livro = (props: IProps) => {
  const { livro, serie } = props;

  const [livroOnCart, setLivroOnCart] = useState<boolean>(false);

  const precoTratado = livro.preco.replace('$', 'R$ ').replace('.', ',');
  const precoNumber = Number(livro.preco.replace('$', ''));

  const maiorParcela = livro.desconto10x
    ? { desconto: livro.desconto10x, parcela: 10 }
    : livro.desconto6x
    ? { desconto: livro.desconto6x, parcela: 6 }
    : livro.desconto3x
    ? { desconto: livro.desconto3x, parcela: 3 }
    : livro.desconto1x
    ? { desconto: livro.desconto1x, parcela: 1 }
    : undefined;

  const menorParcela = livro.descontoAvista
    ? { desconto: livro.descontoAvista, parcela: 0 }
    : livro.desconto1x
    ? { desconto: livro.desconto1x, parcela: 1 }
    : livro.desconto3x
    ? { desconto: livro.desconto3x, parcela: 3 }
    : livro.desconto6x
    ? { desconto: livro.desconto6x, parcela: 6 }
    : livro.desconto10x
    ? { desconto: livro.desconto10x, parcela: 10 }
    : { desconto: 0, parcela: 1 };

  const getLivroOnCart = () => {
    if (typeof window !== 'undefined') {
      const cartBrute = localStorage.getItem('@FGLivraria:cart');

      const cart: ICartDTO | undefined = cartBrute
        ? JSON.parse(cartBrute)
        : undefined;

      if (cart) {
        const itemAlreadyOnCart = cart.items.find(
          itemm => itemm.id === livro.id && itemm.serie_id === serie.id
        );

        if (itemAlreadyOnCart) {
          setLivroOnCart(true);
        } else {
          setLivroOnCart(false);
        }
      }
    }
  };

  const onCartChange = () => {
    if (typeof window !== 'undefined') {
      const item = buildItem(livro, serie.id, 1);
      addItemOnCart(item, serie);
      getLivroOnCart();
    }
  };

  useEffect(() => {
    getLivroOnCart();
  }, [getLivroOnCart]);

  return (
    <li>
      {livro.renderDisciplina ? (
        <DisciplinaContainer>{livro.disciplina}</DisciplinaContainer>
      ) : (
        <></>
      )}
      <LivroContainer>
        <Marcador />
        <div className="bookholder">
          <img
            className="capa"
            src={livro.foto ? livro.foto.url : SemCapa}
            alt={livro.titulo}
          />
          <div className="livroInfo">
            <strong className="titulo">{livro.titulo}</strong>
            {livro.edicao && <p className="edicao">{livro.edicao}</p>}
            {livro.editora && (
              <p className="editora">
                Editora:
                <span>{livro.editora}</span>
              </p>
            )}
            {livro.autor && (
              <p className="autor">
                Autor:
                <span>{livro.autor}</span>
              </p>
            )}
          </div>
          <div className="preco">
            <span className="capa">{precoTratado}</span>
            <strong className="vista">
              R${' '}
              {menorParcela &&
                (precoNumber - (precoNumber * menorParcela.desconto) / 100)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
              {/* <span>un.</span> */}
              {menorParcela.parcela === 0 ? (
                <p>à vista</p>
              ) : (
                <p>em {menorParcela.parcela}x no cartão</p>
              )}
            </strong>
            <span className="parcelas">
              {maiorParcela && (
                <>
                  ou em até {maiorParcela.parcela}x de R${' '}
                  {maiorParcela.desconto &&
                    (
                      (precoNumber -
                        (precoNumber * maiorParcela.desconto) / 100) /
                      maiorParcela.parcela
                    )
                      .toFixed(2)
                      .replace('.', ',')}{' '}
                  no cartão de crédito
                </>
              )}
            </span>
          </div>
          <div className="carrinho">
            {livroOnCart ? (
              <button type="button" onClick={() => onCartChange()}>
                <FaCartArrowDown size={70} />
                <span>Remover do carrinho</span>
              </button>
            ) : (
              <button type="button" onClick={() => onCartChange()}>
                <FaCartPlus size={70} />
                <span>Adicionar ao carrinho</span>
              </button>
            )}
          </div>
        </div>
      </LivroContainer>
    </li>
  );
};

export default Livro;
