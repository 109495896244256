import styled from 'styled-components';

export const ListasHolder = styled.div`
  width: 70%;
  height: auto;

  margin: 40px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  h1 {
    color: var(--main-bg);
    font-size: 38px;
    font-weight: 600;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  h2 {
    color: var(--dark-gray);
    font-size: 18px;
    text-align: center;
    margin: 5px 20px 20px;
  }

  @media (max-width: 1366px) {
    width: 85%;
  }

  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const Holder = styled.div`
  width: 80%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);

  padding: 50px;

  button {
    margin-top: 60px;
    padding: 15px 30px;
    border-radius: 30px;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    list-style: none;

    width: 100%;
    flex: 1 1;
    li {
      width: 100%;
      &:first-child {
        h3 {
          margin-top: 0px;
          padding-top: 0px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      padding: 30px;
    }
  }
`;
