import styled from 'styled-components';

export const DisciplinaContainer = styled.h3`
  width: 100%;
  padding: 20px 0;
  margin: 40px 0 20px;

  border-bottom: 2px solid var(--main-bg);

  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
`;

export const LivroContainer = styled.div`
  width: 100%;
  height: 200px;

  margin: 10px 0;
  border-radius: 30px;
  border: 1px solid #eee;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  div.bookholder {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    img.capa {
      width: 25%;

      height: 85%;
      object-fit: contain;
      object-position: center;
    }

    div.livroInfo {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      line-height: 1.1;
      strong.titulo {
        color: var(--main-bg);
        font-size: 22px;
        margin-bottom: 2px;
        font-weight: 700;
      }
      p.edicao {
        font-size: 14px;
        margin-top: 2px;
        span {
          margin-left: 2px;
        }
      }
      p.editora {
        font-size: 14px;
        margin-top: 20px;
        span {
          margin-left: 2px;
        }
      }
      p.autor {
        font-size: 14px;
        margin-top: 5px;
        span {
          margin-left: 2px;
        }
      }
    }

    div.preco {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      span.capa {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 10px;
        text-align: end;
        text-decoration: line-through;
      }
      strong.vista {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: end;
        span {
          margin-left: -10px;
          font-weight: 400;
          font-size: 10px;
        }
        p {
          margin-top: 5px;
          font-weight: 400;
          font-size: 10px;
        }
      }
      span.parcelas {
        font-size: 14px;
        width: 70%;
        text-align: end;
      }
    }

    div.carrinho {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin: 0px;
        padding: 0px;
        border: none;
        background: none;
        cursor: pointer;

        margin: 0 25px;
        span {
          font-size: 10px;
          line-height: 1.1;
          text-align: center;
          margin-left: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    & {
      height: 600px;
      div.bookholder {
        width: 100%;
        flex-direction: column;
        padding: 20px;

        img.capa {
          width: 100%;
          height: 30%;
          padding: 0 0 10px;
          border-bottom: 1px solid #ccc;
        }

        div.livroInfo {
          width: 100%;
          height: 30%;
          padding: 10px 0;
          border-bottom: 1px solid #ccc;
        }
        div.preco {
          width: 100%;
          height: 30%;
          padding: 10px 0;
          border-bottom: 1px solid #ccc;
          align-items: flex-start;
          strong,
          span,
          p {
            text-align: start !important;
          }
        }
        div.carrinho {
          width: 100%;
          height: 20%;
          padding: 10px 0 0;
        }
      }
    }
  }
`;

export const Marcador = styled.div`
  width: 1px;
  height: 120px;

  background-color: var(--main-bg);

  @media (max-width: 768px) {
    & {
      height: 550px;
      div.bookholder {
        flex-direction: column;
      }
    }
  }
`;
