import React from 'react';
import { PageProps } from 'gatsby';
import Layout from 'components/_layout';
import {
  Banner,
  ColegiosParceirosHolder,
  // ContentHolder,
} from 'styles/pages/index';
import ColegiosParceiros from 'components/ColegiosParceiros';
import Livro from 'components/Livro';
import { ListasHolder, Holder } from 'styles/pages/livros';

const Index = (props: PageProps) => {
  const { location } = props;

  return (
    <Layout pathname={location.pathname}>
      <Banner>
        <h1>FG LIVRARIA</h1>
        <h2>Uma forma descomplicada na compra de listas escolares</h2>
      </Banner>
      <ListasHolder>
        <h1>Mapas</h1>
        <h2>-</h2>
        <Holder>
          <ul>
            <Livro
              key={1}
              livro={{
                id: '1',
                titulo:
                  'Mapa - História do Brasil As Grandes Navegações do Século XV',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
            <Livro
              key={2}
              livro={{
                id: '2',
                titulo:
                  'Mapa - História do Brasil Expansão Territorial Questão de Limites',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
            <Livro
              key={3}
              livro={{
                id: '3',
                titulo: 'Mapa - História do Brasil, Brasil Império',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
            <Livro
              key={4}
              livro={{
                id: '4',
                titulo: 'Mapa - História Medieval as Cruzadas',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
            <Livro
              key={4}
              livro={{
                id: '4',
                titulo: 'Mapa - História Medieval Renascimento Comercial',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
            <Livro
              key={4}
              livro={{
                id: '4',
                titulo: 'Mapa - Século XX Primeira e Segunda Guerra Mundiais',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
            <Livro
              key={5}
              livro={{
                id: '5',
                titulo: 'Mapa - Século XX Era De Conflitos',
                disciplina: 'teste',
                tipo: 'teste',
                preco: '$62.50',
                serie: [
                  {
                    id: '1',
                    name: 'teste',
                    etapa: {
                      id: '1',
                      name: 'teste',
                      colegio: {
                        id: '1',
                        name: 'teste',
                      },
                    },
                  },
                ],
              }}
              serie={{
                id: '1',
                name: 'teste',
                etapa: {
                  id: '1',
                  name: 'teste',
                  colegio: {
                    id: '1',
                    name: 'teste',
                  },
                },
              }}
            />
          </ul>
        </Holder>
      </ListasHolder>
    </Layout>
  );
};

export default Index;
