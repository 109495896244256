import styled from 'styled-components';
import BannerBg2 from 'assets/banner-bg2.jpg';

export const Banner = styled.div`
  width: 100%;
  height: 45vh;

  background: url(${BannerBg2});
  background-repeat: no-repeat;
  background-size: 100% 100% 100%;
  background-position: center;

  padding: 0 3%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    width: 60%;
    text-align: center;
    font-size: 48px;
    color: var(--main-bg);
    font-weight: 600;
    padding: 30px 10px;
    background-color: #fff;
    margin-bottom: 10px;
  }
  h2 {
    width: 60%;
    text-align: center;
    color: #fff;
    background-color: var(--main-bg);
    padding: 10px 10px;
  }
  @media (max-width: 768px) {
    h1,
    h2 {
      width: 90%;
    }
    h1 {
      font-size: 36px;
    }
    h2 {
      font-size: 20px;
    }
  }
`;

export const ColegiosParceirosHolder = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  margin: -80px 3% 0 0;

  @media (max-width: 768px) {
    & {
      margin: -20px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }
  }
`;

export const ContentHolder = styled.div`
  width: 100%;
  margin: 50px 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;
